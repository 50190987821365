import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "#212529",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M12 10L16 14H8L12 10Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }